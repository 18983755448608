import React from "react";
import { Helmet } from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import Icofont from "react-icofont";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import Modal from "react-modal";
import ImportEnrichmentForm from "./components/ImportEnrichmentForm";
import SideMenu from "../../components/menu/sideMenu";

import {retrieveEnrichment} from "../../store/actions/enrichmentActions";
import ProgressBar from "@ramonak/react-progress-bar";
import moment from "moment";
import { setLoadingStatusAction } from '../../store/actions/commonActions';


class EnrichmentPageDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: null,
			expandedItem: null,
			user: "",
			password: "",
			affiliate: true,
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { page, keyword } = this.state;

		this.props.setLoadingStatusAction(true);
		await  this.props.retrieveEnrichment({
			page,
			keyword,
		});
		this.props.setLoadingStatusAction(false);
		
	};

	setPageData() {
		this.props.setPageData({
			title: "Enrichment",
			pageID: "user_enrichment",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				this.submitHandler();
			}
		);
	};

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { enrichmentData, pageData } = this.props;
		let allReports, userPagination, showPagination;
		if (enrichmentData && enrichmentData.data) {
			allReports = enrichmentData.data.length ? enrichmentData.data : null;
			userPagination = enrichmentData.pagination
				? enrichmentData.pagination
				: null;
			console.log(userPagination)
			if(userPagination){
				showPagination = userPagination.page_last > 1 ? true : false;
			}
		}

		return (
			<>
				<Helmet><title>{pageData.title}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
									<span
										className="btn btn-primary ml-3"
										onClick={() => this.openModal()}
									>
										<span className="icon-wrapper">
											<Icofont icon="icofont-upload-alt" />
										</span>
										<span className="icon-text">
											{" "}Add new
										</span>
									</span>
									{ImportLeadModal(this)}
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>Name</th>
													<th>Date added</th>
													<th className="text-center">Progress</th>
													<th className="text-center">Total</th>
													<th className="text-center">Found</th>
													<th className="text-center">Download</th>
												</tr>
											</thead>
											<tbody>
												{allReports
													? allReports.map((item) => {
														const formattedDate = moment(item.date).format('D MMM h:mm A');
														const fullDate = moment(item.date).format('D MMM YYYY h:mm A');
														let percentage = 0;
														if (item.total_data > 0 && item.found !== null && item.found !== undefined) {
															percentage = (item.found / item.total_data) * 100;
														}
															return (
																<tr>
																	<td>{ item.name }</td>
																	<td title={fullDate}>{formattedDate}</td>
																	<td className="text-center">
																		<ProgressBar completed={percentage} />
																	</td>
																	<td className="text-center">{item.total_data}</td>
																	<td className="text-center">{item.found}</td>
																	<td className="text-center">
																		<a href={item.download_url} className="no-dec" target="_blank">
																			<i className="fas fa-download"></i> Download
																		</a>
																	</td>
																</tr>
															);
													  })
													: ""}
											</tbody>
										</table>
										{showPagination ? (
											<div className="section-pagination">
												<font>
													Showing{" "}
													{userPagination.start}{" "}
													to {userPagination.end}{" "}
													out of{" "}
													{userPagination.total}
												</font>
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											</div>
										) : (
											""
										)}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function ImportLeadModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>New Enrichment</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line"></i>
							</button>
						</div>
						<div className="modal-content">
							<ImportEnrichmentForm />
						</div>
					</div>
				</Modal>
			);
		}
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	enrichmentData: state.enrichmentData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveEnrichment,
	setLoadingStatusAction,
};


function EnrichmentPage(props) {
	let navigate = useNavigate();
	return <EnrichmentPageDtl {...props} navigate={navigate} />
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrichmentPage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
