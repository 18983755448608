import React from "react";
import { Helmet } from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import SideMenu from "../../components/menu/sideMenu";

import {retrieveExports} from "../../store/actions/exportsActions";
import ProgressBar from "@ramonak/react-progress-bar";
import moment from "moment/moment";
import { setLoadingStatusAction } from '../../store/actions/commonActions';

class ExportsPageDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: null,
			expandedItem: null,
			user: "",
			password: "",
			affiliate: true,
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { page, keyword } = this.state;
this.props.setLoadingStatusAction(true);
		await  this.props.retrieveExports({
			page,
			keyword,
		});
		this.props.setLoadingStatusAction(false);
	};

	setPageData() {
		this.props.setPageData({
			title: "CSV Exports",
			pageID: "people_exports",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				this.submitHandler();
			}
		);
	};

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { exportData, pageData } = this.props;
		let allReports, userPagination, showPagination;
		if (exportData && exportData.data) {
			allReports = exportData.data.length ? exportData.data : null;
			userPagination = exportData.pagination
				? exportData.pagination
				: null;
			console.log(userPagination)
			if(userPagination){
				showPagination = userPagination.page_last > 1 ? true : false;
			}
		}

		return (
			<>
				<Helmet><title>{pageData.title}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th scope="col">Name</th>
													<th>Date added</th>
													{/* <th className="text-center">Progress</th> */}
													<th className="text-center">Total</th>
													{/* <th className="text-center">Ready</th> */}
													<th className="text-center">Download</th>
												</tr>
											</thead>
											<tbody>
												{allReports
													? allReports.map((item) => {
														const formattedDate = moment(item.date).format('D MMM h:mm A');
														const fullDate = moment(item.date).format('D MMM YYYY h:mm A');

														let percentage = 0;
														if (item.data_total > 0 && item.data_ready !== null && item.data_ready !== undefined) {
															percentage = (item.data_ready / item.data_total) * 100;
														}
														return (

															<tr>
																<th scope="row">{ item.name }</th>
																<td title={fullDate}>{formattedDate}</td>
																{/* <td className="text-center">
																	<ProgressBar completed={percentage} />
																</td> */}
																<td className="text-center">{item.data_total}</td>
																{/* <td className="text-center">{item.data_ready}</td> */}
																<td className="text-center">
																	<a href={item.download_url} target="_blank" className="no-dec"><i className="fas fa-download"></i> Download</a>
																</td>
															</tr>
														);
													})
													: ""}
											</tbody>
										</table>

										{showPagination ? (
											<div className="section-pagination">
												<font>
													Showing{" "}
													{userPagination.start}{" "}
													to {userPagination.end}{" "}
													out of{" "}
													{userPagination.total}
												</font>
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	exportData: state.exportData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveExports,
	setLoadingStatusAction,
};


function ExportsPage(props) {
	let navigate = useNavigate();
	return <ExportsPageDtl {...props} navigate={navigate} />
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportsPage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
