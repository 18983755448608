import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import Icofont from "react-icofont";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";

import setPageData from "../../store/actions/setPageData";
import {retrieveDataFieldCategories, retrieveLead} from "../../store/actions/leadActions";
import Collapsible from "react-collapsible";
import 'react-range-slider-input/dist/style.css';
import Modal from "react-modal";
import ExportLeadForm from "./components/ExportLeadForm";
import Select from 'react-select';
import AsyncSelect from "react-select/async";
import AddToListModal from './components/SaveListModal';
import { searchListAction } from '../../store/actions/listActions';
import { setLoadingStatusAction } from '../../store/actions/commonActions';


class PeopleScreenDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen                  : null,
			modalSaveListIsOpen          : null,
			expandedItem                 : null,
			selected_list_id             : null,
			wherein_job_title            : "",
			wherein_company_name         : "",
			search_departments           : "",
			search_industry              : "",
			company_employees_min        : "",
			company_employees_max        : "",
			annual_revenue_min           : "",
			annual_revenue_max           : "",
			equal_email_status_verified  : "",
			equal_email_status_unverified: "",
			selected_items               : [],
			selected_all                 : false,
			error                        : {},
			page                         : 1,
		};
		this.debounceTimeout = null;
	}

	setValueHandler = (key, value) => {
		this.setState(
			{
				[key]: value,
			},
			() => {
				this.submitHandler();
			}
		);
	};
	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ keyword: value });

		// Clear the previous timeout if the user is still typing
		clearTimeout(this.debounceTimeout);

		// Set a new timeout to execute the setValueHandler after a delay
		this.debounceTimeout = setTimeout(() => {
			this.setValueHandler(name, value);
		}, 1200);
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { page, keyword,
			wherein_job_title,
			wherein_company_name,
			wherein_departments,
			wherein_industry,
			company_employees_min,
			company_employees_max,
			annual_revenue_min,
			annual_revenue_max,
			equal_email_status_verified,
			selected_list_id,
			equal_email_status_unverified
		} = this.state;

		if(equal_email_status_verified === false){
			equal_email_status_verified = "";
		}
		if(equal_email_status_unverified === false){
			equal_email_status_unverified = "";
		}

		this.props.setLoadingStatusAction(true);

		console.log("wherein_company_name");
		console.log(wherein_company_name);
		

		await this.props.retrieveLead({
			keyword,
			list_id: selected_list_id,
			wherein_job_title,
			wherein_company_name,
			wherein_departments,
			wherein_industry,
			company_employees_min,
			company_employees_max,
			annual_revenue_min,
			annual_revenue_max,
			equal_email_status_verified,
			equal_email_status_unverified,
			page,
		});
		this.props.setLoadingStatusAction(false);

	};

	setPageData() {
		this.props.setPageData({
			title: "People Search",
			pageID: "people_search",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				this.submitHandler();
			}
		);
	};
	handleSliderChange = e => {
		console.log("Koi ace");
		console.log(e.target.value);
	};

	searchCompany = async (keyword, callback) => {
		let type = "company_name";
		let items = [{ label: `Search for '${keyword}'`, value: `___${keyword}`, val: keyword }];
		let filtered = await retrieveDataFieldCategories({type, keyword});
		if (Array.isArray(filtered)) {
			items = items.concat(filtered);
		}
		
		callback(items);
	};
	loadLists = async (keyword, callback) => {
		let type = "search_list";
		let filtered = await searchListAction({type, keyword});
		
		callback(filtered);
	};
	searchJobTitles = async (keyword, callback) => {
		let type = "job_title";
		let items = [{ label: `Search for '${keyword}'`, value: `___${keyword}`, val: keyword }];
		let filtered = await retrieveDataFieldCategories({type, keyword});
		if (Array.isArray(filtered)) {
			items = items.concat(filtered);
		}
		
		callback(items);
	};
	searchDepartments = async (keyword, callback) => {
		let type = "departments";
		let items = [{ label: `Search for '${keyword}'`, value: `___${keyword}`, val: keyword }];
		let filtered = await retrieveDataFieldCategories({type, keyword});
		if (Array.isArray(filtered)) {
			items = items.concat(filtered);
		}
		
		callback(items);
	};
	searchIndustry = async (keyword, callback) => {
		let type = "industry";
		let items = [{ label: `Search for '${keyword}'`, value: `___${keyword}`, val: keyword }];
		let filtered = await retrieveDataFieldCategories({type, keyword});
		if (Array.isArray(filtered)) {
			items = items.concat(filtered);
		}
		
		callback(items);
	};

	changeController = (options, key) => {
		// let value = options.map(option => `'${option.label}'`).join(', ');
		this.setState(
			{
				[key]: options,
			},
			() => {
				this.submitHandler();
			}
		);
	};
	listChangeController = (options, key) => {
		this.setState(
			{
				selected_list_id: options.value,
			},
			() => {
				this.submitHandler();
			}
		);
	};


	// Select option start
	onClickCheckAll = (event) => {
		const { selected_items } = this.state;
		const allItems = this.props.leadData.data.map((item) => String(item.id));
	
		if (event.target.checked) {
		  // If "Check All" is checked, combine previously selected items with all new items
		  const updated_selected_items = [...new Set([...selected_items, ...allItems])]; // Avoid duplicates with Set
		  this.setState({ selected_items: updated_selected_items, selected_all: true }); // Update state
		} else {
		  // If "Check All" is unchecked, remove only the items that were selected by the "Check All"
		  const updated_selected_items = selected_items.filter((item) => !allItems.includes(item)); // Keep previously selected, remove the "Check All" items
		  this.setState({ selected_items: updated_selected_items, selected_all: false }); // Update state
		}

	}
	onClickSingleCheckbox = (event) => {
		const { selected_items } = this.state;
		const value = event.target.value; // Get the value (item id)
		const updatedSelectedItems = [...selected_items];

		let selected_all = true;


		// Check if the item already exists in the selectedItems array
		const index = updatedSelectedItems.indexOf(value);
	
		if (index === -1) {
		  // If item is not present, add it
		  updatedSelectedItems.push(value);
		} else {
		  // If item is already present, remove it
		  selected_all = false
		  updatedSelectedItems.splice(index, 1);
		}
		this.setState({
			selected_items : updatedSelectedItems,
			selected_all: selected_all,
		});
	}
	// Select option end
	
	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageID } = this.props.pageData;
		const { selected_items, selected_all } = this.state;

		let { leadData, pageData } = this.props;
		let allReports, userPagination, showPagination;
		if (leadData && leadData.data) {
			allReports = leadData.data.length ? leadData.data : null;
			userPagination = leadData.pagination
				? leadData.pagination
				: null;
				
			if(userPagination){
				showPagination = userPagination.page_last > 1 ? true : false;
			}
		}
		const annualRevenueOptions = [
			{ value: "", label: 'All' },
			{ value: ['100000', '500000'], label: '$100K to $500K' },
			{ value: ['1000000', '10000000'], label: '$1M to $10M' },
			{ value: ['10000000', ''], label: 'Above $10M' },
		]

		const employeesOptions = [
			{ value: "", label: 'All' },
			{ value: ['5', '10'], label: '5 - 10' },
			{ value: ['10', '100'], label: '10 - 100' },
			{ value: ['100', '500'], label: '100 - 500' },
			{ value: ['500', '1000'], label: '500 - 1000' },
			{ value: ['1000', ''], label: 'Above 1000' },
		]

		return (
			<>
				<Helmet><title>{pageData.title}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-2 side-menu data-filtering">
							<div className="filtering-items">
								<form action="" onSubmit={this.submitHandler}>
									<input type="text" name="keyword" className=" p-3 w-100 search-input"
										   onChange={this.handleChange}
										   placeholder="Search people..."/>

									<Collapsible trigger="List" className="collapsible-item item-single">
										<AsyncSelect
												cacheOptions
												loadOptions={this.loadLists}
												defaultOptions
												placeholder="Any list"
												menuPortalTarget={document.body}
												onChange={(options) => {
													this.listChangeController(options, 'list_id')
												}}
											/>
									</Collapsible>
									<Collapsible trigger="Company" className="collapsible-item item-single">
										<AsyncSelect
											isMulti
											cacheOptions
											loadOptions={this.searchCompany}
											defaultOptions
											placeholder="Any company"
											menuPortalTarget={document.body}
											onChange={(options) => {
												this.changeController(options, 'wherein_company_name')
											}}
										/>
									</Collapsible>
									<Collapsible trigger="Job Titles" className="collapsible-item item-single">
										<AsyncSelect
											isMulti
											cacheOptions
											loadOptions={this.searchJobTitles}
											defaultOptions
											placeholder="Any job titles"
											menuPortalTarget={document.body}
											onChange={(options) => {
												this.changeController(options, 'wherein_job_title')
											}}
										/>
									</Collapsible>

									<Collapsible trigger="Employees" className="collapsible-item item-single">
										<Select
											name="options"
											options={employeesOptions}
											className="basic-multi-select"
											classNamePrefix="select"
											placeholder="Show all"
											// value={}
											onChange={(options) => {
												this.setState(
													{
														['company_employees_min']: options.value[0],
														['company_employees_max']: options.value[1],
													},
													() => {
														this.submitHandler();
													}
												);
											}}
											menuPortalTarget={document.body}
										/>
									</Collapsible>

									<Collapsible trigger="Email Status" className="collapsible-item item-single">
										<div className="email-status">
											<div className="single-status">
												<input type="checkbox" id="email_verified" name="equal_email_status_verified"
													   checked={this.state.equal_email_status_verified}
													   onChange={(event) => this.setValueHandler(event.target.name, event.target.checked)}/>
												<label className="badge bg-primary" for="email_verified">Verified</label>
											</div>
											<div className="single-status">
												<input type="checkbox" id="email_unavailable" name="equal_email_status_unverified"
													   checked={this.state.equal_email_status_unverified}
													   onChange={(event) => this.setValueHandler(event.target.name, event.target.checked)}/>
												<label for="email_unavailable" className="badge bg-secondary">Unavailable</label>
											</div>
										</div>
									</Collapsible>
									<Collapsible trigger="Departments" className="collapsible-item item-single">
										<AsyncSelect
											isMulti
											cacheOptions
											loadOptions={this.searchDepartments}
											defaultOptions
											placeholder="Any departments"
											menuPortalTarget={document.body}
											onChange={(options) => {
												this.changeController(options, 'wherein_departments')
											}}
										/>
									</Collapsible>
									<Collapsible trigger="Industry" className="collapsible-item item-single">
										<AsyncSelect
											isMulti
											cacheOptions
											loadOptions={this.searchIndustry}
											defaultOptions
											placeholder="Any job titles"
											menuPortalTarget={document.body}
											onChange={(options) => {
												this.changeController(options, 'wherein_industry')
											}}
										/>
									</Collapsible>
									<Collapsible trigger="Annual Revenue" className="collapsible-item item-single">
										<Select
											name="options"
											options={annualRevenueOptions}
											className="basic-multi-select"
											classNamePrefix="select"
											placeholder="Show all"
											// value={}
											onChange={(options) => {
												this.setState(
													{
														['annual_revenue_min']: options.value[0],
														['annual_revenue_max']: options.value[1],
													},
													() => {
														this.submitHandler();
													}
												);
											}}
											menuPortalTarget={document.body}
										/>
									</Collapsible>

								</form>
									{SaveListModal(this)}
									{ExportLeadModal(this)}
							</div>
						</div>

						<div className="col-md-9">
							<div className="container">
								<div className="page-content">
									<div class="content-header-actions">
										{selected_items.length > 0 && (
										<div class="single-action">
											<div class="add-to-list-action">
												<span class="count">Selected {selected_items.length}</span>
												<button type="button" class="button" onClick={() => this.openSaveListModal()}>Add to list</button>
											</div>
										</div>
										)}
										{selected_items.length < 1 && (
										<div class="single-action">
											<button type="button" className="button" onClick={() => this.openModal()}>Export results</button>
										</div>
										)}
									</div>


									<div className="table-area table-responsive">
										<table className="table table-bordered">
											<thead>
											<tr>
												<th scope="col">
													<input type="checkbox" checked={selected_all} onClick={this.onClickCheckAll}/>
												</th>
												<th scope="col">Name</th>
												<th scope="col">Job Title</th>
												<th scope="col">Company</th>
												<th scope="col">Email</th>
											</tr>
											</thead>
											<tbody>
											{allReports
												? allReports.map((item) => {
													let isChecked = selected_items.includes(String(item.id));
													return (
														<tr data-id={item.id}>
															<td>
																<input type="checkbox"
																	checked={isChecked}
																	value={item.id} onClick={this.onClickSingleCheckbox}/>
															</td>
															<td>
																<b className="text-bold">{ `${item.first_name} ${item.last_name}` }</b>
																<div className="link-group">
																	{item.linkedin_url && (
																		<a href={item.linkedin_url} target="_blank" className="link-single">
																			<Icofont icon="icofont-linkedin" />
																		</a>
																	)}
																	{item.twitter_url && (
																		<a href={item.twitter_url} target="_blank" className="link-single">
																			<Icofont icon="icofont-twitter" />
																		</a>
																	)}
																	{item.facebook_url && (
																		<a href={item.facebook_url} target="_blank" className="link-single">
																			<Icofont icon="icofont-facebook" />
																		</a>
																	)}
																</div>
															</td>
															<td>
																{ item.job_title }
															</td>
															<td>
																{ item.company_name }
																<div className="link-group">
																	{item.website && (
																		<a href={item.website} target="_blank" className="link-single">
																			<Icofont icon="icofont-link" />
																		</a>
																	)}
																	{item.company_phone && (
																		<a href={item.company_phone} target="_blank" className="link-single">
																			<Icofont icon="icofont-ui-call" />
																		</a>
																	)}
																	{item.company_linkedin && (
																		<a href={item.company_linkedin} target="_blank" className="link-single">
																			<Icofont icon="icofont-linkedin" />
																		</a>
																	)}
																</div>
															</td>
															<td>
																<a href={`mailto:${item.email}`} target="_blank">
																	{item.email}
																</a>
															</td>
														</tr>
													);
												})
												: ""}
											</tbody>
										</table>

										{showPagination ? (
											<div className="section-pagination">
												<font>
													Showing{" "}
													{userPagination.start}{" "}
													to {userPagination.end}{" "}
													out of{" "}
													{userPagination.total}
												</font>
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);


		function SaveListModal(that) {
			return (
				<Modal
					isOpen={that.state.modalSaveListIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Save selected data</h3>
							<button className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeSaveListModal();
								}}
							>
								<i className="icofont-close-line"></i>
							</button>
						</div>
						<div className="modal-content">
							<AddToListModal query={that.state}/>
						</div>
					</div>
				</Modal>
			);
		}
		function ExportLeadModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Export peoples</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line"></i>
							</button>
						</div>
						<div className="modal-content">
							<ExportLeadForm query={that.state}/>
						</div>
					</div>
				</Modal>
			);
		}
	}

	openSaveListModal() {
		this.setState({
			modalSaveListIsOpen: true,
		});
	}
	closeSaveListModal() {
		this.setState({
			modalSaveListIsOpen: false,
		});
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	leadData: state.leadData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveLead,
	setLoadingStatusAction,
};


function PeopleScreen(props) {
	let navigate = useNavigate();
	return <PeopleScreenDtl {...props} navigate={navigate} />
}
export default connect(mapStateToProps, mapDispatchToProps)(PeopleScreen);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
