import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import {showSuccess} from "../utils/configToast";
// import * as queryString from "querystring";
import queryString from 'query-string'; // Import the query-string library


export const retrieveLeadDetails = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/lead/details", data)
		.then((res) => {
			responseData = res.data;
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const retrieveLead = (data) => async (dispatch) => {
	// Function to filter out null, undefined, empty strings, and empty arrays
	const filterParams = (params) => {
		return Object.keys(params).reduce((acc, key) => {
			const value = params[key];
			
			// Check if value is not null, undefined, empty string, or empty array
			if (
				value !== null && 
				value !== undefined && 
				!(typeof value === 'string' && value.trim() === '') && 
				!(Array.isArray(value) && value.length === 0)
			) {
				acc[key] = value;
			}
			
			return acc;
		}, {});
	};
	const filteredData = filterParams(data);

	await axios
		.post(`/lead/get`, filteredData)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.LEAD_LIST,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const importLeads = (data) => async (dispatch) => {
	let returnType;
	await axios
		.post("/lead/admin/import", data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			let promoInfo = res.data.data;

			showSuccess("Successfully created");

			dispatch({
				type: Types.LEAD_INSERT,
				payload: {
					promoInfo,
					error: {},
				},
			});
			dispatch(retrieveLead());
			returnType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return returnType;
};

export const newLeadsExport = (data) => async (dispatch) => {
	let returnType;
	await axios
		.post("/lead/export", data)
		.then((res) => {
			// res.data.data;
			showSuccess("Request received");
			returnType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return returnType;
};


export const retrieveDataFieldCategories = async (data) => {
	const queryParams = queryString.stringify(data);

	// Get from server
	let responseData = await axios
		.get(`/lead/data-category?${queryParams}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			manageError(error);
		});

	// Format data
	let newList;
	if (responseData.data !== undefined) {
		newList = responseData.data.map(item => ({
			value: item.id,
			val  : item.id,
			label: item.name,
		}));
	}
	return newList;
};