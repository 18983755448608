import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import {showSuccess} from "../utils/configToast";
import queryString from 'query-string';


export const retrieveListAction = (data) => async (dispatch) => {
	const queryParams = queryString.stringify(data);

	await axios
		.get(`/list?${queryParams}`)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.PEOPLE_LIST,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const insertListAction = (data) => async (dispatch) => {
	let returnType;
	await axios
		.post("/list", data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			let promoInfo = res.data.data;

			showSuccess("Successfully created");

			dispatch({
				type: Types.PEOPLE_LIST_INSERT,
				payload: {
					promoInfo,
					error: {},
				},
			});
			returnType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return returnType;
};



export const searchListAction = async (data) => {
	const queryParams = queryString.stringify(data);

	// Get from server
	let responseData = await axios
		.get(`/list?${queryParams}`)
		.then((res) => {
			return res.data;
		})
		// .catch((error) => {
		// 	console.log(error);
			
		// 	// manageError(error);
		// });

	// Format data
	let newList;
	if (responseData.data !== undefined) {
		newList = responseData.data.map(item => ({
			value: item.id, // Assuming the id is used as value
			label: item.name // Assuming name is used as label
		}));
	}
	return newList;
};