import React from "react";
import { connect } from "react-redux";
import {newLeadsExport} from "../../../store/actions/leadActions";

class ExportLeadForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		name: "",
		csv_file: null,
		formRef: React.createRef(),
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		console.log(event)
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		console.log(event.target.name)
		console.log(selectedFile)
		this.setState({
			[event.target.name]: selectedFile,
		});
	};

	resetFormData = () => {
		this.setState({
			name: "",
		});
		this.state.formRef.current.reset();
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { name } = this.state;
		let {
			keyword,
			wherein_job_title,
			wherein_company_name,
			wherein_departments,
			wherein_industry,
			company_employees_min,
			company_employees_max,
			annual_revenue_min,
			annual_revenue_max,
			equal_email_status_verified,
			selected_list_id,
			equal_email_status_unverified
			} = this.props.query;

		const postData = { name, params: {
				keyword,
				wherein_job_title,
				wherein_company_name,
				wherein_departments,
				wherein_industry,
				company_employees_min,
				company_employees_max,
				annual_revenue_min,
				annual_revenue_max,
				equal_email_status_verified,
				list_id: selected_list_id,
				equal_email_status_unverified
			}};

		let insertReport = await this.props.newLeadsExport(postData);
		this.stopLoading();
		if (insertReport === true) {
			this.resetFormData();
		}
	};

	componentDidMount() {

	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action=""
				  ref={this.state.formRef}>
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error mb-3">
								<label htmlFor="title">Name/title</label>
								<input
									type="text"
									className={
										error.name
											? "form-control is-invalid"
											: "form-control"
									}
									name="name"
									id="name"
									value={this.state.name}
									onChange={this.changeHandler}
									required={true}
								/>
								{error.name && (
									<div className="invalid-feedback">
										{error.name}
									</div>
								)}
							</div>


							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Submit"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	newLeadsExport: newLeadsExport,
};

// function UploadLeadsForm(props) {
// 	let navigate = useNavigate();
// 	return <UploadLeadsFormDtl {...props} navigate={navigate} />
// }
export default connect(mapStateToProps, mapDispatchToProps)(ExportLeadForm);
