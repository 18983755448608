import * as Types from '../actions/types'

const init = {}

const loadingStatusReducer = ( state = init, action) => {
	switch(action.type) {
		case Types.LOADING_STATUS_CHANGE: {
			return action.payload
		}

		default: return state
	}
}

export default loadingStatusReducer