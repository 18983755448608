import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import {showSuccess} from "../utils/configToast";
import queryString from 'query-string';


export const retrieveEnrichment = (data) => async (dispatch) => {
	const queryParams = queryString.stringify(data);

	await axios
		.get(`/enrichment?${queryParams}`)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.ENRICHMENT_LIST,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const importEnrichment = (data) => async (dispatch) => {
	let returnType;
	await axios
		.post("/enrichment", data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			let promoInfo = res.data.data;

			showSuccess("Successfully created");

			dispatch({
				type: Types.LEAD_INSERT,
				payload: {
					promoInfo,
					error: {},
				},
			});
			dispatch(retrieveEnrichment());
			returnType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return returnType;
};